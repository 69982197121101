import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

import firebase from "firebase/app";
import "firebase/functions";
//import "firebase/messaging";

import Home from "./components/Home";
import Channel from "./components/Channel";
import Question from "./components/Question";
import User from "./components/User";
import ChannelsList from "./components/ChannelsList";
import AddQuestion from "./components/AddQuestion";
import AddAnswer from "./components/AddAnswer";
import {USE_EMULATOR} from "./common/const";
import Profile from "./components/Profile";
import Answer from "./components/Answer";
import Search from "./components/Search";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId
});

if(process.env.REACT_APP_useEmulator === "true") {
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      authedUser: null
    }

  }

  _getConfiguration() {
    firebase.app().functions().httpsCallable("apiGetConfiguration")().then(result => {
      window.configuration = result.data;
      //console.log(window.configuration);
    }).catch(error => {console.log(error);});
  }

  componentDidMount() {
    let firebaseui = require('firebaseui');
    this.ui = new firebaseui.auth.AuthUI(firebase.auth());

    this._getConfiguration();



    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.app().functions().httpsCallable("apiGetUser")()
            .then(result => {
              this.setState({authedUser: result.data});
              console.log('!!!!', result.data);
              if(!!result.data.isNew) {
                user.getIdToken(true);
              }
              this._getConfiguration();
            })
            .catch(error => {
              console.log(error);
            });


        //////////////////
        /*setTimeout(() => {
          const messaging = firebase.messaging();

          messaging.getToken({ vapidKey: 'BD55u-oP2RCzfUNZybTHhwU4SDCSt07Mb3nCzUEzaM6Zqyc38v1U2tE-klPeCK_pA75gBS9wLdpZrsEpuFznjFM' }).then((currentToken) => {
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              console.log('currentToken=', currentToken);
              firebase.app().functions().httpsCallable("apiUpdateUser")({fcmToken: currentToken}).then(result => {
                //this.setState({ redirect: "/channel/" + this.props.match.params.channelId});
              });
              // ...
            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
              // ...
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // ...
          });

          messaging.onMessage((payload) => {
            console.log('Message received. ', payload);
            // ...
          });
        }, 5000);*/

      } else {
      }
    });
  }

  onSignOutClick = () => {
    firebase.auth().signOut();
  }

  onAuthClick = () => {

    this.ui.start('#firebaseui-auth-container', {
      signInOptions: [
        // List of OAuth providers supported.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ],
      // Other config options...
    });
  }


  render() {

    return (
      <Router>
        <div>
          <div id={"firebaseui-auth-container"}></div>
          {<Link to={"/"}>Главная</Link>} &nbsp;&nbsp;
          {!this.state.authedUser && <a href={"#"} onClick={this.onAuthClick} className={"button button1"}>Авторизация</a>}
          {this.state.authedUser && <Link to={"/profile"}>Профиль</Link>} &nbsp;&nbsp;
          {<Link to={"/search"}>Поиск</Link>} &nbsp;&nbsp;
          {this.state.authedUser && <a href={"#"} onClick={this.onSignOutClick}>Выход</a>}
        </div>
        <div>

          <Switch>

            <Route path={`/user/:id`}>
              <User />
            </Route>

            <Route path={`/question/:id`}>
              <Question />
            </Route>

            <Route path={`/answer/:id`}>
              <Answer />
            </Route>

            <Route path={`/channel/:id`}>
              <Channel user={this.state.authedUser}/>
            </Route>

            <Route path={`/add-question/:channelId`}>
              <AddQuestion user={this.state.authedUser}/>
            </Route>

            <Route path={`/add-answer/:questionId/:answerId?`}>
              <AddAnswer user={this.state.authedUser}/>
            </Route>

            <Route path={`/profile`}>
              <Profile user={this.state.authedUser}/>
            </Route>

            <Route path={`/search`}>
              <Search />
            </Route>

            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
