import React from "react";
import ChannelsList from "./ChannelsList";
import {
  Link
} from "react-router-dom";


class Home extends React.Component {

  constructor(props) {
    super(props);



  }


  render() {

    return (
        <div>
          <h2>Главная</h2>
          <ChannelsList />
        </div>

    );
  }
}

export default Home;