import React from "react";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/functions";
import {
  Link
} from "react-router-dom";


class ChannelsList extends React.Component {

  constructor(props) {
    super(props);

    console.log("!!!1", this.props);

    this.state = {channels: []}

    //console.log(result);
  }

  componentDidMount() {

    console.log('!!!!!!1');

    firebase.app().functions().httpsCallable("apiGetChannels")()
        .then(result => {this.setState({channels: result.data})})
        .catch(error => {
          console.log(error);
        })

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  componentWillUnmount() {
    console.log('!!!!!!2');
    //this.ui.delete();
  }

  render() {

    return (
        <div>
          <div id={"firebaseui-auth-container"}></div>
          <h3>Список каналов</h3>
          <ul>
            {this.state.channels.map(channel => {
              return <li><Link to={"/channel/"+channel.id}>{channel.title}</Link> - {channel.countQuestions} вопросов</li>
            })}
          </ul>
        </div>

    );
  }
}

export default ChannelsList;