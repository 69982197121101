import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/functions";
import {formatDate} from "../common/utils";


class Question extends React.Component {

  constructor(props) {
    super(props);

    console.log("!!!1", this.props);

    this.state = {question: null, answers: []};
  }

  updateData() {
    console.log("!!!2", this.props);

    this.id = this.props.match.params.id;

    firebase.app().functions().httpsCallable("apiGetQuestionOrAnswer")({id: this.id}).then(questionResult => {
      this.setState({question: questionResult.data})
    }).catch(error => {
      console.log(error);
    });

    this.updateAnswers();
  }

  updateAnswers() {
    let answerParams = {
      questionId: this.id,
      backward: true
    };

    if(this.state.answers.length) {
      answerParams.startAfterId = this.state.answers[this.state.answers.length-1].id;
    }

    firebase.app().functions().httpsCallable("apiGetQuestionAnswers")(answerParams).then(result => {
      this.setState({answers: result.data})
    }).catch(error => {
      console.log(error);
    });
  }

  componentDidMount() {
    this.updateData();
  }

  onClickMoreAnswers = () => {
    this.updateAnswers();
  }

  onLikeDislikeQuestion = (like) => {
    firebase.app().functions().httpsCallable("apiLikeDislikeQuestion")({questionId: this.id, like: like}).then(questionResult => {
      this.updateData();
    }).catch(error => {
      console.log(error);
    });
  }

  onLikeDislikeAnswer = (answerId, like) => {
    firebase.app().functions().httpsCallable("apiLikeDislikeAnswer")({answerId: answerId, like: like}).then(answerResult => {
      this.updateData();
    }).catch(error => {
      console.log(error);
    });
  }


  render() {

    if(!this.state.question)  return null;

    const question = this.state.question;
    const answers = this.state.answers;

    return (
        <div>
          <h2>
            <Link to={"/"}>Главная</Link> {" / "}
            <Link to={"/channel/" + question.channelId}>{question.channelId}</Link>
            {" / " + this.state.question.text}
          </h2>
          <h3>Лайков {!!question.countLikes && ", " + question.countLikes + " штук"}:</h3>
          <h3>Ответы на вопрос {!!question.countAnswers && ", " + question.countAnswers + " штук"}:</h3>
          <ul>{!!answers && answers.map(answer => {
            return <li>{formatDate(new Date(answer.createdTime))} {answer.text} (Ответ от <Link to={"/user/"+answer.author.userId}>{answer.author.userName}</Link>) {answer.countReplies > 0 ? ' - ' + answer.countReplies + ' реплаев' : ''}{answer.countLikes > 0 ? ' - ' + answer.countLikes + ' лайков' : ''}
              <Link to={"/answer/"+answer.id} className={"button button2"}>Смотреть реплаи</Link>
              <Link to={"/add-answer/"+question.id+"/"+answer.id} className={"button button2"}>reply</Link>
              <a href={"#"} onClick={() => this.onLikeDislikeAnswer(answer.id, true)} className={"button button5"}>Like</a>
              <a href={"#"} onClick={() => this.onLikeDislikeAnswer(answer.id, false)} className={"button button5"}>Dislike</a>

              <br />
              Превью первых реплаев:
              <ul>{answer.previewReplies.length > 0 && answer.previewReplies.map(reply => {
                return <li><i>{reply.text}</i></li>
              })}
              </ul>
            </li>
          })}
          </ul>
          {!!this.state.answers.length && <a class={"button button2 small"} href="#" onClick={this.onClickMoreAnswers}>Еще ответ</a>}
          <br /><br />
          <Link to={"/add-answer/"+question.id} className={"button button2"}>Добавить свой ответ</Link>
          <a href={"#"} onClick={() => this.onLikeDislikeQuestion(true)} className={"button button5"}>Like</a>
          <a href={"#"} onClick={() => this.onLikeDislikeQuestion(false)} className={"button button5"}>Dislike</a>

        </div>
    );
  }
}

export default withRouter(Question);
