import React from "react";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/functions";
import {formatDate} from "../common/utils";

class Profile extends React.Component {

  constructor(props) {
    super(props);

    console.log("!!!1", this.props);

    this.state = {
      fields: {
        userName: "",
        firstName: "",
        lastName: "",
        fullName: "",
        profileColor: "",
        profileImageUrl: "",
        profileVideoUrl: "",
        profileAboutText: "",
        userImageUrl: ""
      },

      activityFeedEvents: [],

      followingQA: [],

      userPublicData: {}
    };

  }

  componentDidMount() {
    if(!this.props.user) {
      this.setState({redirect: "/"});
      return;
    }

    console.log(this.props.user);
    let fields = Object.assign({}, this.state.fields);
    Object.keys(fields).forEach(field => {fields[field]=this.props.user[field]});
    this.setState({fields: fields});

    firebase.app().functions().httpsCallable("apiGetActivityFeed")().then(result => {
      this.setState({activityFeedEvents: result.data.events})
    }).catch(error => {
      console.log(error);
    });

    firebase.app().functions().httpsCallable("apiGetQuestionsAndAnswersForFollowing")().then(result => {
      console.log(result.data);
      this.setState({followingQA: result.data})
    }).catch(error => {
      console.log(error);
    });



    firebase.app().functions().httpsCallable("apiGetUserPublicData")({userId: this.props.user.id}).then(result => {
      this.setState({userPublicData: result.data})
      console.log(result.data);
    }).catch(error => {
      console.log(error);
    });


  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /*let fields = Object.assign({}, this.state.fields);

    Object.keys(fields).forEach(field => {fields[field]=this.props.user[field]});

    this.setState({fields: fields});

    console.log(fields);*/
  }

  updateData(clear = false) {

    /*firebase.app().functions().httpsCallable("apiGetUser")().then(userResult => {
      console.log(userResult);
      this.setState({user: userResult.data})
    }).catch(error => {
      console.log(error);
    });*/
  }

  handleChange = (event) => {

    let fields = Object.assign({}, this.state.fields);
    fields[event.target.name] = event.target.value;
    console.log(event.target.name, event.target.value, fields);
    this.setState({fields: fields});
  }

  handleSubmit = (event) => {

    firebase.app().functions().httpsCallable("apiUpdateUser")(this.state.fields).then(result => {
      //this.setState({ redirect: "/channel/" + this.props.match.params.channelId});
    });

    event.preventDefault();
  }

  onUserImageFileChange = (e) => {
    console.log(e);

    console.log('uploadNewCover start');
    let newMetadata = {
      customMetadata: {
        [window.configuration.userImageMetadataKeys.userId]: this.props.user.id,
      }
    };
    //Грузим новую картинку
    try {
      const path = window.configuration.storagePaths.userImage.replace(window.configuration.templateKeys.userId, this.props.user.id);
      console.log(path);
      firebase.app().storage().ref().child(path).put(e.target.files[0], newMetadata)
    }
    catch (err) {console.error(err);}
  }

  onProfileAudioFileChange = (e) => {
    let newMetadata = {
      customMetadata: {
        [window.configuration.userImageMetadataKeys.userId]: this.props.user.id,
      }
    };
    //Грузим новую картинку
    try {
      const path = window.configuration.storagePaths.profileAudio.replace(window.configuration.templateKeys.userId, this.props.user.id);
      console.log(path);
      firebase.app().storage().ref().child(path).put(e.target.files[0], newMetadata)
    }
    catch (err) {console.error(err);}
  }



  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    if(!this.props.user) return null;

    return (
        <div>
          <h2><Link to={"/"}>Главная</Link> / {!!this.props.user && this.props.user.id}</h2>

          <form onSubmit={this.handleSubmit}>

            <label>
              userName<input type="text" value={this.state.fields.userName} name={"userName"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              firstName<input type="text" value={this.state.fields.firstName} name={"firstName"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              lastName<input type="text" value={this.state.fields.lastName} name={"lastName"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              fullName<input type="text" value={this.state.fields.fullName} name={"fullName"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              about<input type="text" value={this.state.fields.profileAboutText} name={"profileAboutText"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              profileColor<input type="text" value={this.state.fields.profileColor} name={"profileColor"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              profileImageUrl<input type="text" value={this.state.fields.profileImageUrl} name={"profileImageUrl"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              profileVideoUrl<input type="text" value={this.state.fields.profileVideoUrl} name={"profileVideoUrl"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              userImageUrl<input type="text" value={this.state.fields.userImageUrl} name={"userImageUrl"} onChange={this.handleChange} />
            </label>
            <br/>
            <input type="submit" className={"button button5"} value="Отправить" />
          </form>
          <br/><br/>
          <form action="http://localhost:5001/pingpong-dev-4ccb5/us-central1/uploadFile" method="post" enctype="multipart/form-data">
            Загрузка аватарки:
            <input type="file" name="myFileName" onChange={this.onUserImageFileChange}/>
          </form>

          <form action="http://localhost:5001/pingpong-dev-4ccb5/us-central1/uploadFile" method="post"
                encType="multipart/form-data">
            Загрузка аудио:
            <input type="file" name="myFileName" onChange={this.onProfileAudioFileChange}/>
          </form>

          <h3>Публичные данные профиля</h3>
          <ul>
            {Object.keys(this.state.userPublicData).map(key => <li><b>{key}</b>: {this.state.userPublicData[key]}</li>)}
          </ul>

          <h3>Activity feed</h3>
          <div>
            <ul>
              {!!this.state.activityFeedEvents && this.state.activityFeedEvents.map(event => {
                return <li>{formatDate(new Date(event.timestamp))} {event.type}</li>
              })}
            </ul>

          </div>

          <h3>Following</h3>
          <div>
            <ul>
              {!!this.state.followingQA && this.state.followingQA.map(qa => {
                return <li>
                  {formatDate(new Date(qa.createdTime))} &nbsp;
                  {qa.isQuestion && "Вопрос "}
                  {qa.isAnswer && (qa.isReply ? "Реплай " : "Ответ ")} &nbsp;
                  {qa.text}
                </li>
              })}
            </ul>

          </div>

        </div>
    );
  }
}

export default withRouter(Profile);
