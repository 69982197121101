import React from "react";
import { withRouter } from "react-router";
import { Redirect, Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/functions";
import { v4 as uuidv4 } from 'uuid';


class AddQuestion extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      audioUrl: 'test',
      audioPath: '',
      videoUrl: 'test',
    };

  }

  handleChange = (event) => {
    this.setState({text: event.target.value});
  }

  handleSubmit = (event) => {

    firebase.app().functions().httpsCallable("apiAddQuestion")({
      channelId: this.props.match.params.channelId,
      text: this.state.text,
      type: "test type",
      videoUrl: this.state.videoUrl,
      audioUrl: this.state.audioUrl,
      audioPath: this.state.audioPath,
      thumbnailUrl: "test thumbnailUrl url",
    }).then(result => {
      this.setState({ redirect: "/channel/" + this.props.match.params.channelId});
    });

    event.preventDefault();
  }

  componentDidMount() {
    console.log("!!!2", this.props);
  }

  onVideoFileChange = (e) => {
    try {
      let path = window.configuration.storagePaths.contentVideo
          .replace(window.configuration.templateKeys.userId, this.props.user.id)
          .replace(window.configuration.templateKeys.random, uuidv4());
      firebase.app().storage().ref().child(path).put(e.target.files[0], {})
    }
    catch (err) {console.error(err);}
  }

  onAudioFileChange = async (e) => {
    try {
      let path = window.configuration.storagePaths.contentAudio
          .replace(window.configuration.templateKeys.userId, this.props.user.id)
          .replace(window.configuration.templateKeys.random, uuidv4());
      await firebase.app().storage().ref().child(path).put(e.target.files[0], {});
      let url = await firebase.app().storage().ref().child(path).getDownloadURL();

      this.setState({
        audioUrl: url,
        audioPath: path
      });

    }
    catch (err) {console.error(err);}
  }


  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
        <div>
          <h2><Link to={"/"}>Главная</Link> / Новый вопрос в канал</h2>
          <br />
          <form onSubmit={this.handleSubmit}>
            <label>
              Текст вопроса:
              <input type="text" value={this.state.value} onChange={this.handleChange} />
            </label>
            <br />
            <input type="submit" className={"button button5"} value="Отправить" />
          </form>

          <br/><br/>
          Добавить видео:
          <form action="" method="post"
                encType="multipart/form-data">
            <input type="file" name="myFileName" onChange={this.onVideoFileChange}/>
          </form>

          <br/><br/>
          Добавить аудио:
          <form action="" method="post"
                encType="multipart/form-data">
            <input type="file" name="myFileName" onChange={this.onAudioFileChange}/>
          </form>
        </div>
    );
  }
}

export default withRouter(AddQuestion);
