import React from "react";
import { withRouter } from "react-router";


class SearchHit extends React.Component {

  constructor(props) {
    super(props);

  }

  async componentDidMount() {

    console.log(this.props);
  }


  render() {

    return (
        <div className={'search-hit'}>
          {this.props.hit._highlightResult.text && <div className={'text'} dangerouslySetInnerHTML={{__html: '<i>ТЕКСТ:</i> ' + this.props.hit._highlightResult.text.value}}></div>}
          {this.props.hit._highlightResult.audioTranscription && <div className={'transcription'} dangerouslySetInnerHTML={{__html: '<i>ТРАНСКРИПЦИЯ:</i> ' + this.props.hit._highlightResult.audioTranscription.value}}></div>}
        </div>
    );
  }
}

export default withRouter(SearchHit);
