import React from "react";
import { withRouter } from "react-router";
import { Redirect, Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/functions";
import { v4 as uuidv4 } from 'uuid';



class AddAnswer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      audioPath: '',
    };

  }

  handleChange = (event) => {
    this.setState({text: event.target.value});
  }

  handleSubmit = (event) => {

    firebase.app().functions().httpsCallable("apiAddAnswer")({
      questionId: this.props.match.params.questionId,
      answerId: this.props.match.params.answerId,
      text: this.state.text,
      videoUrl: "test video url",
      audioUrl: this.state.audioUrl,
      audioPath: this.state.audioPath,
      thumbnailUrl: "test thumbnailUrl url",
    }).then(result => {
      this.setState({ redirect: "/question/" + this.props.match.params.questionId});
    });

    event.preventDefault();
  }

  onAudioFileChange = async (e) => {
    try {
      let path = window.configuration.storagePaths.contentAudio
          .replace(window.configuration.templateKeys.userId, this.props.user.id)
          .replace(window.configuration.templateKeys.random, uuidv4());
      await firebase.app().storage().ref().child(path).put(e.target.files[0], {});
      let url = await firebase.app().storage().ref().child(path).getDownloadURL();

      this.setState({
        audioUrl: url,
        audioPath: path
      });

    }
    catch (err) {console.error(err);}
  }

  componentDidMount() {
    console.log("!!!2", this.props);
  }


  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
        <div>
          <h2><Link to={"/"}>Главная</Link> / Новый ответ на вопрос</h2>
          <form onSubmit={this.handleSubmit}>
            <label>
              Текст ответа:
              <input type="text" value={this.state.value} onChange={this.handleChange} />
            </label>
            <input type="submit" className={"button button5"} value="Отправить" />
          </form>

          <br/><br/>
          Добавить аудио:
          <form action="" method="post"
                encType="multipart/form-data">
            <input type="file" name="myFileName" onChange={this.onAudioFileChange}/>
          </form>
        </div>
    );
  }
}

export default withRouter(AddAnswer);
