import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import algoliasearch from 'algoliasearch/lite';
// Import components
import {
  InstantSearch,
  Hits,
  SearchBox,
} from 'react-instantsearch-dom';

import "firebase/functions";
import SearchHit from "./SearchHit";

const searchClient = algoliasearch(
    'GGL2T3JYK4',
    '262dce2f00f89ab94c65cf76e4b5efe6'
);

const searchIndex = searchClient.initIndex("qa");

class Search extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hits: []
    };
  }

  async componentDidMount() {
  }

  onSearchInputChange = (event) => {

    if(event.target.value.le <= 2)  return;

    searchIndex.search(event.target.value)
        .then(({ hits }) => {
          console.log(hits);
          this.setState({hits: hits})
        })
        .catch(err => {
          console.log(err);
        });

  }


  render() {

    return (
        <div>
          <h1>Поиск</h1>
          <input type="text" name="searchInput" onChange={this.onSearchInputChange}/>
          {this.state.hits.map(hit => {
            return <SearchHit hit={hit}/>
          })}
        </div>
    );
  }
}

export default withRouter(Search);
