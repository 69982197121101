import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import {PROD_DOMAIN, USE_EMULATOR} from "./const";

/*function getFunctionsDomain() {
    if(process.env.REACT_APP_useEmulator === "true") {
    } else {
        return process.env.REACT_APP_functionsDomain
    }
}*/

function formatDate(date) {
  return (date).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
}

export {
  formatDate
}
